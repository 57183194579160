import bigNumber from 'bignumber.js';
import intl from 'react-intl-universal';
import TronWeb from 'tronweb';
import emptyImg from '../assets/images/tableNoData.svg';
import Config from '../config';
import axios from 'axios';

const { chain } = Config;

export const tronWeb = new TronWeb({
  fullHost: chain.fullHost
});

bigNumber.config({ EXPONENTIAL_AT: 1e9 });
bigNumber.prototype._toFixed = function (...arg) {
  return new bigNumber(this).isNaN() ? '0' : new bigNumber(this.toFixed(...arg)).toString();
};
bigNumber.prototype._toFixedNew = function (...arg) {
  return new bigNumber(this.toFixed(...arg)).toString();
};
bigNumber.prototype._toBg = function () {
  return this;
};
bigNumber.prototype._toHex = function () {
  return `0x${this.toString(16)}`;
};

bigNumber.prototype._toIntegerDown = function () {
  return new bigNumber(this).isNaN() ? '0' : new bigNumber(this).integerValue(this.ROUND_DOWN);
};

export const BigNumber = bigNumber;

export const toBigNumber = tronWeb.toBigNumber;
export const toDecimal = tronWeb.toDecimal;

export const handle = property => {
  return function (a, b) {
    const val1 = a[property];
    const val2 = b[property];
    return val2 - val1;
  };
};

export const toFixedDown = (num, decimals = 4, { miniText = false } = {}) => {
  if (miniText && BigNumber(num).lt(miniText) && !BigNumber(num).eq(0)) {
    return `< ${miniText}`;
  }
  const d = toBigNumber(10).pow(decimals);
  return BigNumber(num).times(d).integerValue(BigNumber.ROUND_DOWN).div(d).toFixed(decimals);
};

export const toFixedUp = (num, decimals = 4, { miniText = false } = {}) => {
  if (miniText && BigNumber(num).lt(miniText) && !BigNumber(num).eq(0)) {
    return `< ${miniText}`;
  }
  const d = toBigNumber(10).pow(decimals);
  return BigNumber(num).times(d).integerValue(BigNumber.ROUND_UP).div(d).toFixed(decimals);
};

export const toFixedRound = (num, decimals = 4, { miniText = false } = {}) => {
  if (miniText && BigNumber(num).lt(miniText) && !BigNumber(num).eq(0)) {
    return `< ${miniText}`;
  }
  const d = toBigNumber(10).pow(decimals);
  return BigNumber(num).times(d).integerValue(BigNumber.ROUND_HALF_UP).div(d).toFixed(decimals);
};

export const toBigNumberNew = function (value) {
  if (typeof value === 'string') {
    value = value.replace(/,/g, '');
  }
  return new bigNumber(value);
};

export const _toFormat = function (value) {
  if (bigNumber(value).toFormat() === 'NaN') {
    return '';
  }
  if (typeof value === 'string') {
    value = value.replace(/,/g, '');
    const arr = value.split('.');
    const formatStr = bigNumber(arr[0]).toFormat();
    if (formatStr === 'NaN') {
      return '';
    }
    if (arr[1] !== undefined) {
      return `${formatStr}.${arr[1]}`;
    }
    return formatStr;
  }
  const formatStr = bigNumber(value).toFormat();

  return formatStr;
};

export const formatNumber = (
  num,
  { dp = 2, rm = 1, percent = false, miniText = false, needDolar = false, cutZero = false } = {}
) => {
  if (num === '--' || BigNumber(num).isNaN()) return '--';
  num = BigNumber(num);
  if (miniText && num.lt(miniText) && !num.eq(0)) {
    return `< ${needDolar ? '$' : ''}${miniText}`;
  }

  if (percent && num.gte(100)) {
    num = BigNumber(100);
  }

  let result = `${needDolar ? '$' : ''}${BigNumber(num).toFormat(dp, rm)}`;

  if (cutZero) {
    const parts = result.toString().split('.');

    parts[1] = parts[1] ? parts[1].replace(/0+?$/, '') : '';
    // if (parts[1] && parts[1].length === 1) {
    //   parts[1] = parts[1] + '0';
    // }
    result = parts[0] + (parts[1] ? `.${parts[1]}` : '');
  }

  return result;
};

export const formatAPY = (num, gt0 = true) => {
  let miniText = 0.01;
  let maxText = 9999.99;
  if (num === '--' || BigNumber(num).isNaN()) return '--';
  num = BigNumber(num);
  if (!gt0) {
    if (num.eq(0)) {
      return '0.00';
    }
  }
  if (num.lt(miniText) && !num.eq(0)) {
    return `< ${miniText}`;
  }

  if (num.gt(maxText)) {
    // return `> ${maxText}`;
    return '>' + BigNumber(maxText).toFormat(2, 1);
  }

  return BigNumber(num).toFormat(2, 1);
};

export const SUPPOER_LOCALES = [
  {
    name: 'English',
    value: 'en-US'
  },
  {
    name: '繁體中文',
    value: 'zh-TC'
  }
];

export const emptyReactNode = type => {
  let centerStyle = {
    paddingTop: '50px',
    paddingBottom: '50px',
    position: 'relative'
  };
  let imgStyle = {
    width: '10%',
    maxWidth: '200px',
    minWidth: '100px'
  };
  let textStyle = {
    position: 'absolute',
    transform: 'translate(-50%)',
    top: '40%',
    left: '50%',
    color: '#84869E',
    fontSize: '12px'
  };
  return (
    <div className="center" style={centerStyle}>
      <div className="empty-img">
        <img src={emptyImg} alt="" style={imgStyle} />
        <span style={textStyle}>{type === 'mint' ? '' : intl.get('no_data')}</span>
      </div>
    </div>
  );
};

export const emptyReactNodeNew = () => {
  return (
    <div className="j-nodata">
      <div>
        <div className="nodata-img"></div>
        <div className="nodata-text">{intl.get('burn.burn_no_data')}</div>
      </div>
    </div>
  );
};

export const amountFormat = (amount, decimal, { miniText = false, needDolar = false } = {}) => {
  // console.log('amount', amount)
  if (amount === '--' || BigNumber(amount).isNaN()) return '--';
  if (miniText || miniText === 0) {
    if (BigNumber(amount).gte(0) && BigNumber(amount).lt(miniText)) {
      return `< ${needDolar ? '$' : ''}${miniText}`;
    }
  }
  amount = BigNumber(amount);
  let precision = BigNumber(10).pow(decimal);
  if (amount.gt(1e9)) {
    return `${needDolar ? '$' : ''}${amount
      .div(1e9)
      .times(precision)
      .integerValue(tronWeb.BigNumber.ROUND_DOWN)
      .div(precision)
      .toFixed(decimal)}B`;
  } else if (amount.gt(1e6)) {
    return `${needDolar ? '$' : ''}${amount
      .div(1e6)
      .times(precision)
      .integerValue(tronWeb.BigNumber.ROUND_DOWN)
      .div(precision)
      .toFixed(decimal)}M`;
  } else if (amount.gt(1e3)) {
    return `${needDolar ? '$' : ''}${amount
      .div(1e3)
      .times(precision)
      .integerValue(tronWeb.BigNumber.ROUND_DOWN)
      .div(precision)
      .toFixed(decimal)}K`;
  }
  return `${needDolar ? '$' : ''}${amount
    .times(precision)
    .integerValue(tronWeb.BigNumber.ROUND_DOWN)
    .div(precision)
    .toFixed(decimal)}`;
};

export const getDepositApy = item => {
  return BigNumber(item.supplyratePerblock).div(Config.tokenDefaultPrecision).times(Config.blockPerYear).times(100);
};

export const getTotalApy = (item, assetList) => {
  let depositApy = '--';
  let wstUSDTDepositApyWithIncrement = '--';
  let mintApy = '--';
  let mintApyUSDD = '--';
  let mintApyWithUSDD = '--';
  let totalApy = '--';
  let totalCash = '--';
  let underlyingIncrementApy = '--';
  // const miningStart = true;
  try {
    depositApy = item.depositedAPY ? BigNumber(item.depositedAPY).times(100) : getDepositApy(item);
    underlyingIncrementApy = item.underlyingIncrementApy ? BigNumber(item.underlyingIncrementApy).times(100) : 0;
    // if (miningStart) {

    //mintApy = assetList[item.jtokenAddress] ? assetList[item.jtokenAddress].totalAPYNEW : '--';
    mintApy = assetList[item.jtokenAddress] ? assetList[item.jtokenAddress].totalAPYNEWUSDD : '--';
    mintApyUSDD = assetList[item.jtokenAddress] ? assetList[item.jtokenAddress].totalAPYNEWUSDD : '--';
    mintApyUSDD = BigNumber(mintApyUSDD).toFixed(2, 1);
    mintApyWithUSDD = mintApyUSDD;
    totalCash = assetList[item.jtokenAddress]?.totalCash;

    // } else {
    //   mintApy = assetList[item.jtokenAddress] ? assetList[item.jtokenAddress].apy : '--';
    // }
    totalApy = depositApy;
    // if (mintApy !== '--') {
    //   totalApy = totalApy.plus(mintApy);
    // }
    if (Config.holdingTokens.includes(item.collateralSymbol)) {
      // totalApy = underlyingIncrementApy;
      // totalApy = BigNumber(underlyingIncrementApy).plus(depositApy);

      wstUSDTDepositApyWithIncrement = BigNumber(
        BigNumber(BigNumber(item.depositedAPY).plus(1)).times(BigNumber(item.underlyingIncrementApy).plus(1)).minus(1)
        // .plus(mintApyWithUSDD)
      ).times(100);
      totalApy = wstUSDTDepositApyWithIncrement;
    }
    if (mintApyUSDD !== '--' && !Config.hideMarketMintIcon.includes(item.collateralSymbol)) {
      // totalApy = totalApy.plus(mintApyUSDD);
      let totalApyPlus = BigNumber(
        formatNumber(BigNumber(totalApy).toFixed(2, 1), 2, { miniText: 0.01, per: true })
      ).plus(formatNumber(mintApyUSDD, 2, { miniText: 0.01, per: true }));
      if (totalApyPlus.toString() === 'NaN') {
        totalApy = totalApy.plus(mintApyUSDD);
      } else {
        totalApy = totalApyPlus;
      }
      // console.log(totalApy.toString());
    }

    // if (['BTT', 'USDD', 'sTRX'].includes(item.collateralSymbol)) {
    //   console.log(
    //     item.collateralSymbol,
    //     'item.collateralSymbol',
    //     BigNumber(wstUSDTDepositApyWithIncrement).toString(),
    //     'wstUSDTDepositApyWithIncrement',
    //     BigNumber(depositApy).toString(),
    //     'depositApy',
    //     BigNumber(mintApyUSDD).toString(),
    //     'mintApyUSDD',
    //     BigNumber(totalApy).toString(),
    //     '*******',
    //     BigNumber(item.underlyingIncrementApy).toString(),
    //     '*******'
    //   );
    // }
  } catch (error) {
    console.log(`getTotalApy error:`, error);
  }

  return {
    depositApy,
    wstUSDTDepositApyWithIncrement,
    underlyingIncrementApy,
    mintApy,
    mintApyUSDD,
    mintApyWithUSDD,
    totalApy,
    totalCash
  };
};

export const marketLendAvailable = item => {
  const { borrowLimit, totalBorrow, totalCash, collateralDecimal } = item;
  let totalCashValue = BigNumber(totalCash).div(BigNumber(10).pow(collateralDecimal));

  if (BigNumber(borrowLimit).eq(0) || BigNumber(borrowLimit).eq(-1)) return totalCashValue;

  let totalBorrowValue = BigNumber(totalBorrow).div(BigNumber(10).pow(collateralDecimal));
  let borrowValue = BigNumber(borrowLimit).minus(totalBorrowValue);
  borrowValue = BigNumber(borrowValue).lt(0) ? 0 : borrowValue;

  return BigNumber(totalCashValue).gt(borrowValue) ? borrowValue : totalCashValue;
};

export const randomSleep = (time = 1000) => {
  return new Promise((reslove, reject) => {
    const timeout = parseInt(Math.random() * time);
    setTimeout(() => {
      reslove();
    }, timeout);
  });
};

import BTFS from '../assets/images/tokens/btfs.png';
import BTTC from '../assets/images/tokens/bttc.png';
import NFT from '../assets/images/tokens/nft.png';
import SUN from '../assets/images/tokens/sun.svg';
import TRX from '../assets/images/tokens/trx.svg';
import TUSD from '../assets/images/tokens/tusd.png';
import USDD from '../assets/images/tokens/usdd.svg';
import USDJ from '../assets/images/tokens/usdj.svg';
import WIN from '../assets/images/tokens/win.png';

export const tokenIcons = {
  trx: TRX,
  bttc: BTTC,
  btt: BTTC,
  usdd: USDD,
  usdj: USDJ,
  tusd: TUSD,
  sun: SUN,
  nft: NFT,
  btfs: BTFS,
  win: WIN
};

export const getAnnouncements = async () => {
  let lang = window.localStorage.getItem('lang') || 'en-us';
  lang = lang.toLowerCase();
  const urlCn = `https://justlendorg.zendesk.com/api/v2/help_center/zh-cn/categories/900001436023/articles.json?sort_by=created_at&sort_order=desc&per_page=3`;
  const urlEn =
    'https://justlendorg.zendesk.com/api/v2/help_center/en-us/categories/900001436023/articles.json?sort_by=created_at&sort_order=desc&per_page=3';

  const { data } = await axios.get(lang === 'en-us' ? urlEn : urlCn);

  if (data?.articles && data?.articles.length > 0) {
    return data.articles.slice(0, 3);
  }
  return [];
};

export const getCurrentBlockNumber = async () => {
  const res = await tronWeb.trx.getCurrentBlock();
  if (res && res.block_header) {
    return res.block_header.raw_data.number;
  }
  return null;
};
