import _ from 'lodash';
import React, { lazy, Suspense, useEffect } from 'react';
import intl from 'react-intl-universal';
import { BrowserRouter, Route, Routes, Redirect } from 'react-router-dom';
import './assets/css/common.scss';
import { NoServiceModal } from './components/no-service-modal/index.jsx';
import { SUPPOER_LOCALES } from './utils/helper';

const Home = lazy(() => import('./pages/home'));
const DaoPage = lazy(() => import('./pages/dao'));
const JstPage = lazy(() => import('./pages/jst'));
const ForbidPage = lazy(() => import('./pages/forbid'));
const GrantsPage = lazy(() => import('./pages/grants'));

const locales = {
  'zh-CN': require('./locales/zh-CN.json'),
  'zh-TC': require('./locales/zh-TC.json'),
  'en-US': require('./locales/en-US.json')
};

const IRouter = () => {
  let currentLocale = intl.determineLocale({
    urlLocaleKey: 'lang',
    cookieLocaleKey: 'lang'
  });

  useEffect(() => {
    const { origin, pathname, search, hash } = window.location;

    if (!hash.includes('?') && hash.includes('&')) {
      const url = origin + pathname + search + hash.replace('&', '?');
      window.location.replace(url);
    }
  }, []);

  const str = window.location.search;

  if (str.indexOf('lang=') > -1 && str.split('lang=')[1]?.length >= 5) {
    currentLocale = str.split('lang=')[1]?.slice(0, 5);
  } else {
    currentLocale = window.localStorage.getItem('lang') || 'en-US';
  }

  if (!_.find(SUPPOER_LOCALES, { value: currentLocale })) {
    currentLocale = 'en-US';
  }

  // let currentLocale = 'en-US'; // later will deleted
  window.localStorage.setItem('lang', currentLocale);
  intl.init({
    currentLocale,
    locales
  });

  return (
    <BrowserRouter>
      <div className="main">
        <div className="footer-bg"></div>

        <Suspense fallback={<div></div>}>
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/dao" element={<DaoPage />} />
            <Route path="/jst" element={<JstPage />} />
            <Route path="/forbid" element={<ForbidPage />} />
            <Route path="/grantsdao" element={<GrantsPage />} />
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </Suspense>
      </div>
      {/* <NoServiceModal dark={true}></NoServiceModal> */}
    </BrowserRouter>
  );
};

export default IRouter;
