import React from 'react';
import { Modal, Checkbox } from 'antd';
import './style.scss';
import { BUTTON_BG } from './config.js';
import axios from 'axios';
import isMobile from 'ismobilejs';
import { Link } from 'react-router-dom';

export class NoServiceModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      hasAgree: getHasAgree()
    };
  }
  componentDidMount() {
    if (!this.state.hasAgree) {
      getTipStatus().then(res => {
        if (res) {
          this.setState({
            visible: res
          });
        }
      });
    }
  }
  onClick = () => {
    this.setState({
      visible: false,
      hasAgree: true
    });
    setHasAgree();
  };
  render() {
    const { visible, hasAgree } = this.state;
    const { dark } = this.props;
    return (
      <Modal
        closable={false}
        width={mobile ? 'calc(100vw - 40px)' : '460px'}
        visible={!hasAgree && visible}
        wrapClassName={`no-service-modal${dark ? ' dark' : ''}`}
        footer={null}
        zIndex={99999}
      >
        <div className="content">
          <div className="icon"></div>
          {/* <p className="text">
            無法向中國大陸用戶提供產品或服務，請立即停止使用。點擊 “我同意”
            即表示您確認並承諾，您不是中國大陸用戶或未在中國大陸使用本服務。
          </p> */}
          <p className="text">
            Mainland China users are not eligible to use the products or services offered by current platform. By
            clicking "I Agree" you represent and warrant that you are not a Mainland China user, nor accessing the
            platform from Mainland China.
          </p>
          <div onClick={this.onClick} className="btn" style={{ background: BUTTON_BG }}>
            {/* 我同意 / I Agree */}I Agree
          </div>
        </div>
      </Modal>
    );
  }
}

export class NoServiceModalAll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      popShow: getPopShow(),
      checked: false
    };
  }
  componentDidMount() {
    if (this.state.popShow) {
      getTipStatus().then(res => {
        if (res) {
          this.setState({
            visible: res
          });
        }
      });
    }
  }
  onClick = () => {
    const { checked } = this.state;
    if (!checked) {
      return;
    }
    const now = Date.now();
    window.localStorage.setItem('closedNoServiceModalAll', now);
    this.setState({
      popShow: false
    });
  };

  onChange = flag => {
    this.setState({
      checked: flag
    });
  };

  render() {
    const { visible, popShow, checked } = this.state;
    const { dark } = this.props;
    return (
      <Modal
        closable={false}
        width={mobile ? 'calc(100vw - 40px)' : '460px'}
        open={popShow && visible}
        wrapClassName={`no-service-modal-all${dark ? ' dark' : ''}`}
        footer={null}
        zIndex={99999}
      >
        <div className="content">
          <div className="icon"></div>
          <div className="textWrap">
            {/* <p className="text">
              JustLend DAO 不對中國大陸用戶提供任何產品或服務，如果您是中國大陸用戶，請立即停止使用！
            </p> */}
            <p className="text">
              JustLend DAO does not provide any products or services to users from mainland China. If you are a user
              from mainland China, please stop using it immediately!
            </p>
          </div>

          <div className="checkbox-wrap">
            <Checkbox className="" onChange={e => this.onChange(e.target.checked)}>
              {/* 我承諾，我不是中國大陸用戶並且承諾沒有在中國區域使用此產品！
              <br /> */}
              <span className="en">
                I promise that I am not a user from mainland China, and I promise that I am not using this product in
                mainland China!{' '}
              </span>
            </Checkbox>
          </div>
          <div className="btn-wrap">
            <div onClick={this.onClick} className={'btn' + (checked ? '' : ' disabled')}>
              {/* 繼續使用 / Continue */}
              Continue
            </div>
            <Link className="exit" to="forbid">
              {/* 退出 / Exit */}
              Exit
            </Link>
          </div>
        </div>
      </Modal>
    );
  }
}

const instance = axios.create({
  validateStatus: function (status) {
    return status >= 200 && status < 300; // default
  }
});
const mobile = isMobile(window.navigator).any;
const getTipStatus = async () => {
  try {
    await instance.get('https://rioj.ablesdxd.link/?time=' + Date.now());
  } catch (error) {
    if (error.code) {
      return true;
    }
  }
};

const getHasAgree = () => {
  return window.localStorage.getItem('NoService:hasAgree');
};
const setHasAgree = () => {
  window.localStorage.setItem('NoService:hasAgree', 'true');
};

const getPopShow = () => {
  const closedNoServiceModalAll = window.localStorage.getItem('closedNoServiceModalAll');
  const lastClosed = closedNoServiceModalAll ? Number(closedNoServiceModalAll) : 0;
  const now = Date.now();
  if (now - lastClosed > 24 * 60 * 60 * 1000) {
    return true;
  } else {
    return false;
  }
};
