const env = process.env.REACT_APP_ENV;
const Config = {
  chain: {
    privateKey: '01',
    fullHost: 'https://api.trongrid.io'
  },
  service: {
    host: 'https://labc.ablesdxd.link',
    appHost: 'https://labc.ablesdxd.link',
    grantsHost: 'https://labc.ablesdxd.link',
    stUSDTHost: 'https://api.stusdt.org',
    marketsPath: '/justlend/markets',
    dashboardPath: '/dashboard',
    homepagePath: '/justlend/portal',
    grantsPath: '/justlend/grants',
    yieldInfos: '/justlend/yieldInfos',
    tronBull: '/sunProject/tronbull',
    defitvl: '/api/defiTvl',
    proposalListPath: '/justlend/gov/proposalList',
    strxDashboard: '/strx/dashboard',
    stUSDTDashboard: '/stusdt/dashboard',
    burnInfo: '/jst/burn/dashboard',
    burnListInfo: '/jst/burn/list',
    burnStatus: '/justlend/gov/status',
    betaTest: '/justlend/config',
    appLink: 'https://app.justlend.org/'
  },
  defaultIntervalTime: 60000,
  defaultPrecision: 1e6,
  tokenDefaultPrecision: 1e18,
  yielders: [
    {
      pool: 'TE2RzoSV3wFK99w6J9UnnZ4vLfXYoxvRwP',
      sunSupply: 8400,
      day: 14
    },
    {
      pool: 'TXJgMdjVX5dKiQaUi9QobwNxtSQaFqccvd',
      sunSupply: 8400,
      day: 14
    },
    {
      pool: 'TL5x9MtSnDy537FXKx53yAaHRRNdg9TkkA',
      sunSupply: 2800,
      day: 14
    },
    {
      pool: 'TGBr8uh9jBVHJhhkwSJvQN2ZAKzVkxDmno',
      sunSupply: 2800,
      day: 14
    },
    {
      pool: 'TRg6MnpsFXc82ymUPgf5qbj59ibxiEDWvv',
      sunSupply: 2800,
      day: 14
    },
    {
      pool: 'TLeEu311Cbw63BcmMHDgDLu7fnk9fqGcqT',
      sunSupply: 2800,
      day: 14
    },
    {
      pool: 'TWQhCXaWz4eHK4Kd1ErSDHjMFPoPc9czts',
      sunSupply: 2800,
      day: 14
    },
    {
      pool: 'TUY54PVeH6WCcYCd6ZXXoBDsHytN9V5PXt',
      sunSupply: 2800,
      day: 14
    },
    {
      pool: 'TR7BUFRQeq1w5jAZf1FKx85SHuX6PfMqsV',
      sunSupply: 2100,
      day: 14
    },
    {
      pool: 'TFpPyDCKvNFgos3g3WVsAqMrdqhB81JXHE',
      sunSupply: 2100,
      day: 14
    }
  ],
  sTRXPath: 'https://app.justlend.org/strx',
  stableTokenPath: 'https://app.justlend.org/homeNew',
  hideMarketMintIcon: ['BTC', 'TRX', 'sTRX', 'SUNOLD', 'WBTT', 'ETH', 'ETHOLD', 'BUSD', 'TUSD', 'USDC', 'USDT', 'USDJ'],
  holdingTokens: ['wstUSDT', 'sTRX']
};

let devConfig = {};
if (env === 'test') {
  devConfig = {
    chain: {
      privateKey: '01',
      fullHost: 'https://api.nileex.io'
    },
    service: Object.assign(Config.service, {
      // host: 'http://123.56.166.152:10079'     // justlend app 所使用的 (但缺 JST APY, Liquidity)
      // host: 'http://47.252.26.188:10091/'
      // host: 'https://labc.ablesdxd.link'   // 有jst頁面後改用, 但 Total Supply, Total Borrow 數據不一樣
      // host: 'http://3.137.0.94:10091', // 缺 JST APY, 首頁 Total Supply, Total Borrow 數據不一樣
      host: 'https://apitest.justlend.org', // 缺 JST APY, 首頁 Total Supply, Total Borrow 數據不一樣
      appHost: 'https://apitest.justlend.org',
      grantsHost: 'https://apidev.justlend.org',
      // grantsHost: 'https://apitest.justlend.org',
      appLink: 'https://test.justlend.org/'
    }),
    yielders: [
      {
        pool: 'TKM7w4qFmkXQLEF2MgrQroBYpd5TY7i1pq',
        sunSupply: 8400,
        day: 14
      },
      {
        pool: 'TT6Qk1qrBM4MgyskYZx5pjeJjvv3fdL2ih',
        sunSupply: 8400,
        day: 14
      },
      {
        pool: 'TLBoPBNAfrBPxq3rTQzSKzTXrRjjAqaiJ6',
        sunSupply: 2800,
        day: 14
      },
      {
        pool: 'TYf16sZLR9uXpm63bXsRCNQMQFvqqvXQ2t',
        sunSupply: 2800,
        day: 14
      },
      {
        pool: 'TZ51C31Zh3qBSRBnTmbcuRX1rqyhzoCe8Q',
        sunSupply: 2800,
        day: 14
      },
      {
        pool: 'TBGCExAC3iRk5EXAVXEer3bwhTi9EN9rht',
        sunSupply: 2800,
        day: 14
      },
      {
        pool: 'TXNg6MoDTDEZKwPzTAdnzdQwfTF4LdU1QW',
        sunSupply: 2800,
        day: 14
      },
      {
        pool: 'TAj5XxJtkrEDvTT7mTsS3uqMcvSCp82cnR',
        sunSupply: 2800,
        day: 14
      },
      {
        pool: 'TYVr8QECrDkf6EAiKehok5FF3ckWV5Ds7k',
        sunSupply: 2800,
        day: 14
      }
    ],
    sTRXPath: 'https://test.justlend.org/strx',
    stableTokenPath: 'https://test.justlend.org/homeNew'
  };
}
if (env === 'backendPro') {
  devConfig = {
    service: Object.assign(Config.service, {
      host: 'https://grey-justlend.ablesdxd.link',
      appHost: 'https://grey-justlend.ablesdxd.link'
    })
  };
}
export default Object.assign(Config, devConfig);
